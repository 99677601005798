import Keycloak from "keycloak-js";

const keycloak = new Keycloak( {
    url: 'https://keycloak.powerlog.cuatrodigital.com/',
    realm: 'master',
    clientId: 'powerlog',
  })
;
  
  export default keycloak;
  