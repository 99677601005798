import React, { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import SearchByDate from "../components/SearchByDate";
import SearchByPort from "../components/SearchByPort";
import "./Home.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Typography from "@mui/material/Typography";
import SearchByShips from "../components/SearchByShips";

function HomePage() {
  const { keycloak } = useKeycloak();
  const [selectedDates, setSelectedDates] = useState({});
  const [showNotification, setShowNotification] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [sortOrder, setSortOrder] = useState({});
  const [ports, setPorts] = useState([]);
  const [ships, setShips] = useState([]);
  const [selectedShip, setSelectedShip] = useState("");

  const [selectedPortOrigin, setSelectedPortOrigin] = useState("");
  const [selectedPortDestination, setSelectedPortDestination] = useState("");
  const [showNoResultsNotification, setShowNoResultsNotification] = useState(
    false
  );
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    const fetchPorts = async () => {
      try {
        const response = await axios.post("https://back.powerlog.cuatrodigital.com/api/ports");
        setPorts(response.data);
      } catch (error) {
        console.error("Error fetching ports:", error);
      }
    };

    fetchPorts();
  }, []);

  useEffect(() => {
    const fetchShips = async () => {
      try {
        const response = await axios.post("https://back.powerlog.cuatrodigital.com/api/ships");
        setShips(response.data);
      } catch (error) {
        console.error("Error fetching ports:", error);
      }
    };

    fetchShips();
  }, []);

  useEffect(() => {
    // Solo realizar la solicitud al backend si hay datos de búsqueda previa
    if (selectedDates.fecha_llegada && selectedDates.fecha_salida) {
      const fetchLastResponse = async () => {
        try {
          const response = await axios.get(
            "https://back.powerlog.cuatrodigital.com/api/last-response"
          );
          console.log("Last response from backend:", response.data);
  
          // Ordenar los resultados inicialmente por costo de predicción de menor a mayor
          const sortedResults = response.data.sort(
            (a, b) => a.coste_prediccion - b.coste_prediccio
          );
          setSearchResults(sortedResults);
          // Mostrar la notificación si la respuesta está vacía
          if (sortedResults.length === 0) {
            setShowNoResultsNotification(true);
          } else {
            setShowNoResultsNotification(false);
          }
        } catch (error) {
          console.error("Error fetching last response from backend:", error);
          setShowNoResultsNotification(true)
        }
      };
  
      fetchLastResponse();
    }
  }, [selectedDates]);


  useEffect(() => {
    setSelectedDates({});
    setSortOrder({});
    setSearchResults([]);
    setSelectedPortOrigin("");
    setSelectedPortDestination("");
    setShowNotification(false);
    setShowNoResultsNotification(false);
  }, []);

  
  const handleShipChange = (name, value) => {
    if (name === "naviera") {
      setSelectedShip(value)
    } else {
    setSelectedShip(value);
    }
  };
  const handleSearch = async () => {
    setShowNotification(false);
    setShowNoResultsNotification(false)
    setSearching(true)

    if (!selectedDates.fecha_llegada || !selectedDates.fecha_salida) {
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 5000);
      setSearching(false)
      return;
    }
    const formattedFechaSalida = selectedDates.fecha_salida
      .toISOString()
      .slice(0, 10);
    const formattedFechaLlegada = selectedDates.fecha_llegada
      .toISOString()
      .slice(0, 10);



    const navieraID = selectedShip !== "" ? selectedShip : '-1'

    const requestData = {
      id: 0,
      puerto_origen: selectedPortOrigin,
      puerto_destino: selectedPortDestination,
      naviera: navieraID,
      fecha_salida: formattedFechaSalida,
      fecha_llegada: formattedFechaLlegada,
    };

    const dataToSend = [requestData];
    console.log(requestData);

    try {
      const response = await axios.post(
        "https://back.powerlog.cuatrodigital.com/api/send-request",
        dataToSend
      );
      console.log("Response from backend:", response.data);
      setSearchResults(response.data);

      if (response.data.length === 0) {
        setShowNoResultsNotification(true)
      } else {
        setShowNoResultsNotification(false)
      }
    } catch (error) {
      console.error("Error al enviar la solicitud al backend:", error);
      setShowNoResultsNotification(true)
    } finally {
      setSearching(false)
    }
  };

  const handleDateChange = (name, value) => {
    setSelectedDates((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePortChange = (name, value) => {
    if (name === "puerto_origen") {
      setSelectedPortOrigin(value);
    } else {
      setSelectedPortDestination(value);
    }
  };

  const handleSort = (column) => {
    let newSortOrder = {};

    if (sortOrder[column] === "asc") {
      newSortOrder[column] = "desc";
    } else {
      newSortOrder[column] = "asc";
    }

    setSortOrder(newSortOrder);

    const sortedResults = [...searchResults].sort((a, b) => {
      if (newSortOrder[column] === "asc") {
        return a[column] - b[column];
      } else {
        return b[column] - a[column];
      }
    });

    setSearchResults(sortedResults);
  };

  return (
    <div className="home-page">
      {keycloak.authenticated ? (
        <div>
          <Accordion className="acordeon">
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography className="titulosFuente">
                Búsqueda de datos
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div style={{ display: "flex", width: "100%" }}>
                  <div style={{ width: "40%" }}>
                    <SearchByDate
                      selectedDates={selectedDates}
                      onDateChange={handleDateChange}
                    />
                  </div>

                  <div style={{ width: "40%" }}>
                    <div className="busquedaPuertos">
                    <p>Puerto de origen</p>
                    <SearchByPort
                      ports={ports}
                      onSelectPort={handlePortChange}
                      name="puerto_origen"
                    />
                    </div>
                    <div className="busquedaPuertos">
                    <p>Puerto de destino</p>
                    <SearchByPort
                      ports={ports}
                      onSelectPort={handlePortChange}
                      name="puerto_destino"
                    />
                    </div>
                  </div>
                  <div style={{ width: "30%" }}>
                    <div className="busquedaPuertos">
                    <p>Selecciona naviera</p>
                    <SearchByShips
                      ships={ships}
                      onSelectShips={handleShipChange}
                      name="naviera"
                    />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "2rem",
                  }}
                >
                  <button className={`busquedaButton${searching ? ' searching' : ''}`} onClick={handleSearch} disabled={searching}>
                    {searching ? "Buscando..." : "Buscar"}
                  </button>
                </div>
                <p style={{ fontSize: "12px", marginBottom: "0rem" }}>
                  * Datos obligatorios para la búsqueda
                </p>

              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="acordeon">
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel2-header"
            >
              <Typography className="titulosFuente">
                Resultados de predicción:
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {searchResults.length > 0 ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Tipo de Servicio</th>
                        <th>Naviera</th>
                        <th>Puerto de Origen</th>
                        <th>Puerto de Destino</th>
                        <th onClick={() => handleSort("duracion_prediccion")}>
                          Duración Predicción{" "}
                          {sortOrder["duracion_prediccion"] === "asc" ? (
                            <ArrowDownwardIcon />
                          ) : (
                            <ArrowUpwardIcon />
                          )}
                        </th>
                        <th>Duración Predicción Máxima</th>
                        <th>Duración Predicción Mínima</th>
                        <th onClick={() => handleSort("coste_prediccion")}>
                          Coste Predicción{" "}
                          {sortOrder["coste_prediccion"] === "asc" ? (
                            <ArrowDownwardIcon />
                          ) : (
                            <ArrowUpwardIcon />
                          )}
                        </th>
                        <th>Coste Predicción Máximo</th>
                        <th>Coste Predicción Mínimo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchResults.map((result, index) => (
                        <tr key={index}>
                          <td>{result.tipo_servicio}</td>
                          <td>{result.naviera_nom}</td>
                          <td>{result.puerto_origen_nom}</td>
                          <td>{result.puerto_destino_nom}</td>
                          <td>{result.duracion_prediccion} horas</td>
                          <td>{result.duracion_prediccion_max} horas</td>
                          <td>{result.duracion_prediccion_min} horas</td>
                          <td>{result.coste_prediccion.toFixed(2)} €</td>
                          <td>{result.coste_prediccion_max.toFixed(2)} €</td>
                          <td>{result.coste_prediccion_min.toFixed(2)} €</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No hay resultados</p>
                )}
              </Typography>
            </AccordionDetails>
          </Accordion>
          {showNotification && (
            <div className="notification">
              <p className="notification-text">
                Es necesario seleccionar fechas
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className="nolog">
          <p className="nologFont">Es necesario iniciar sesión.</p>
        </div>
      )}
    </div>
  );
}

export default HomePage;
