import React, { useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import "./Navbar.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faUser, faShip } from '@fortawesome/free-solid-svg-icons';

const Nav = () => {
  const { keycloak, initialized } = useKeycloak();
  const [isActive, setIsActive] = useState(false);

  const handleMenuClick = () => {
    setIsActive(!isActive);
  };


  const getUserInitial = () => {
    if (keycloak && keycloak.tokenParsed && keycloak.tokenParsed.preferred_username) {
      return keycloak.tokenParsed.preferred_username.charAt(0).toUpperCase();
    }
    return "";
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <h1 className="navbar-logo"><FontAwesomeIcon icon={faShip} style={{marginRight:"0.5rem"}}/> Powerlog</h1>
        <div
          className={`menu-icon ${isActive ? "active" : ""}`}
          onClick={handleMenuClick}
        >

        </div>
        <ul className={`nav-menu ${isActive ? "active" : ""}`}>
          <li className="nav-item"> {!keycloak.authenticated && (
              <span
                type="text"
                className="nav-link"
              >
              </span>
            )}
            {!!keycloak.authenticated && (
              <span
                type="text"
                className="nav-link"
              >
              <div style={{display: "flex"}}>
                <div className="avatar">
                  <span style={{color: "#187F87"}}>{getUserInitial()}</span>
                </div>    
                <div style={{marginLeft: "1rem"}}> 
                  {`${keycloak.tokenParsed.name}`}

                </div> 
              </div>
              </span>   
            )}</li>
          <li className="nav-item">
            {!keycloak.authenticated && (
              <button
                type="button"
                className="nav-link"
                onClick={() => keycloak.login()}
              >
               <span style={{marginRight: "1rem"}}><FontAwesomeIcon icon={faUserPlus} /> </span>Iniciar sesión
              </button>
            )}
            {!!keycloak.authenticated && (
              <button
                type="button"
                className="nav-link"
                onClick={() => keycloak.logout()}
              >
                Cerrar sesión 
              </button>
              
            )}
          </li>
         
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
