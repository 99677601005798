import * as React from 'react';
import { useKeycloak } from "@react-keycloak/web";
import './UserData.css';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Typography from '@mui/material/Typography';




function UserData() {
  const { keycloak } = useKeycloak();
  const authTime = keycloak.tokenParsed.auth_time;
const formattedAuthTime = new Date(authTime * 1000).toLocaleString();
  return (
    <>
    <div className="home-page">
      {keycloak.authenticated ? (
        <div>
        <Accordion  className='acordeon'>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
        <Typography className='titulosFuente'>Datos de usuario</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div style={{display:"flex", width: "100%"}}>
              <form>
                <label className='inputTitle'>Username: </label>
                <input className='inputInt' value={`${keycloak.tokenParsed.preferred_username}`} readOnly />
              </form>
              <form>
                <label className='inputTitle'>Nombre y apellidos: </label>
                <input className='inputInt' value={`${keycloak.tokenParsed.name}`} readOnly></input>
              </form>
            <form>
                <label className='inputTitle'>Email: </label>
                <input className='inputInt' value={`${keycloak.tokenParsed.email}`} readOnly></input>
              </form>
              <form>
                <label className='inputTitle'>Rol: </label>
                <input className='inputInt' value={`${keycloak.tokenParsed.realm_access.roles}`} readOnly></input>
              </form>
              <form>
                <label className='inputTitle'>Auth Time: </label>
                <input className='inputInt' value={formattedAuthTime} readOnly></input>
              </form>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
        </div>
        
      ) : (
        <p>Es necesario iniciar sesión para ver los datos de usuario.</p>
      )}
    </div>
    </>
  );
}

export default UserData;
