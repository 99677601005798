// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#portInput {
    width: 300px; 
    height: 40px;
    margin: 0.5rem;
    border: 1px solid #187F87;
    border-radius: 10px;
    padding-left: 0.8rem;
    font-family: 'Kanit', sans-serif;

}

.portInputTitle {
    font-family: 'Kanit', sans-serif;
}

.buttonPort {
    width: 100px; 
    height: 40px;
    margin: 0.5rem;
    border: 1px solid #187F87;
    border-radius: 10px;
    background-color: #18808723;
    font-family: 'Kanit', sans-serif;
}


.selectPuertos {
    width: 300px;
    height: 40px;
    margin: 0.5rem;
    border: 1px solid #187F87;
    border-radius: 10px;
    padding-left: 0.8rem;
    font-family: 'Kanit', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchPort.css"],"names":[],"mappings":"AAGA;IACI,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,yBAAyB;IACzB,mBAAmB;IACnB,oBAAoB;IACpB,gCAAgC;;AAEpC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,yBAAyB;IACzB,mBAAmB;IACnB,2BAA2B;IAC3B,gCAAgC;AACpC;;;AAGA;IACI,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,yBAAyB;IACzB,mBAAmB;IACnB,oBAAoB;IACpB,gCAAgC;AACpC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;600;700&display=swap');\r\n\r\n\r\n#portInput {\r\n    width: 300px; \r\n    height: 40px;\r\n    margin: 0.5rem;\r\n    border: 1px solid #187F87;\r\n    border-radius: 10px;\r\n    padding-left: 0.8rem;\r\n    font-family: 'Kanit', sans-serif;\r\n\r\n}\r\n\r\n.portInputTitle {\r\n    font-family: 'Kanit', sans-serif;\r\n}\r\n\r\n.buttonPort {\r\n    width: 100px; \r\n    height: 40px;\r\n    margin: 0.5rem;\r\n    border: 1px solid #187F87;\r\n    border-radius: 10px;\r\n    background-color: #18808723;\r\n    font-family: 'Kanit', sans-serif;\r\n}\r\n\r\n\r\n.selectPuertos {\r\n    width: 300px;\r\n    height: 40px;\r\n    margin: 0.5rem;\r\n    border: 1px solid #187F87;\r\n    border-radius: 10px;\r\n    padding-left: 0.8rem;\r\n    font-family: 'Kanit', sans-serif;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
