import React from "react";
import "../components/SearchPort.css"
function SearchByPort({ ports, onSelectPort, name }) {
  return (
    <select className="selectPuertos" onChange={(e) => onSelectPort(name, e.target.value)}>
      <option value="">Selecciona un puerto</option>
      {ports.map((port) => (
        <option key={port.id} value={port.id}>
          {port.name}
        </option>
      ))}
    </select>
  );
}

export default SearchByPort;
