import React from "react";
import "../components/SearchPort.css"
function SearchByShips({ ships, onSelectShips, name }) {
  return (
    <select className="selectPuertos" onChange={(e) => onSelectShips(name, e.target.value)}>
      <option value="">Selecciona una naviera</option>
      {ships.map((ship) => (
        <option key={ship.id} value={ship.id}>
          {ship.name}
        </option>
      ))}
    </select>
  );
}


export default SearchByShips;
