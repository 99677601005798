import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "./SearchDate.css"
import es from "date-fns/locale/es";
registerLocale("es", es);

function SearchByDate({ selectedDates, onDateChange }) {
  return (
    <div className="search-by-date-container">
      <div className="search-by-date">
        <div className="date-picker-container">
          <p style={{marginRight: "1rem"}}>Fecha de salida:* </p>
          <div className="date-picker-wrapper">
            <span style={{ fontWeight: "5", marginLeft: "1.5rem"}}>Desde:</span>
            <DatePicker
              selected={selectedDates.fecha_salida}
              onChange={date => onDateChange("fecha_salida", date)}
              id="fecha_salida"
              dateFormat="dd/MM/yyyy"
              className="date-picker"
              locale={es}
            />
          </div>
        </div>
      </div>
      <div className="search-by-date">
        <div className="date-picker-container">
          <p style={{marginRight: "1.2rem"}}>Fecha de llegada:* </p>
          <div className="date-picker-wrapper">
            <span style={{ fontWeight: "5", marginLeft: "0.5rem"}}>Desde:</span>
            <DatePicker
              selected={selectedDates.fecha_llegada}
              onChange={date => onDateChange("fecha_llegada", date)}
              id="fecha_llegada"
              dateFormat="dd/MM/yyyy"
              className="date-picker"
              locale={es}
            />
          </div>

        </div>
      </div>
    </div>
  );
}

export default SearchByDate;