// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputInt {
    width: 250px; 
    height: 40px;
    margin: 0.5rem;
    border: 1px solid #187F87;
    border-radius: 10px;
    padding-left: 0.8rem;
    font-family: 'Kanit', sans-serif;

}

.inputTitle {
    font-family: 'Kanit', sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/pages/UserData.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,yBAAyB;IACzB,mBAAmB;IACnB,oBAAoB;IACpB,gCAAgC;;AAEpC;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".inputInt {\r\n    width: 250px; \r\n    height: 40px;\r\n    margin: 0.5rem;\r\n    border: 1px solid #187F87;\r\n    border-radius: 10px;\r\n    padding-left: 0.8rem;\r\n    font-family: 'Kanit', sans-serif;\r\n\r\n}\r\n\r\n.inputTitle {\r\n    font-family: 'Kanit', sans-serif;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
