import React from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./services/keycloakConfig";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Nav from "./components/Nav";
import WelcomePage from "./pages/HomePage";
import PrivateRoute from "./helpers/PrivateRoute";
import UserPage from "./pages/UserData";

function App() {
  return (
    <>
      <div>
        <ReactKeycloakProvider authClient={keycloak}>
          <Nav />
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<WelcomePage />} />
              <Route
                path="/userdata"
                element={
                  <PrivateRoute>
                    <UserPage />
                  </PrivateRoute>
                }
              />
            </Routes>
          </BrowserRouter>
        </ReactKeycloakProvider>
      </div>
    </>
  );
}

export default App;
